import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as _91email_93ZkXNA4vPJlMeta } from "/opt/build/repo/pages/approve/[email].vue?macro=true";
import { default as additionalJi7prYMKYnMeta } from "/opt/build/repo/pages/join/additional.vue?macro=true";
import { default as contactvr68oTkYO3Meta } from "/opt/build/repo/pages/join/contact.vue?macro=true";
import { default as detailsdBFhYJ0O2xMeta } from "/opt/build/repo/pages/join/details.vue?macro=true";
import { default as index3LFjt2xye0Meta } from "/opt/build/repo/pages/join/index.vue?macro=true";
import { default as membership5yx0mVDfiAMeta } from "/opt/build/repo/pages/join/membership.vue?macro=true";
import { default as termsTJSThGMVa9Meta } from "/opt/build/repo/pages/join/terms.vue?macro=true";
import { default as thank_45youJUMZOYodHFMeta } from "/opt/build/repo/pages/join/thank-you.vue?macro=true";
import { default as join9vB1FLnYxNMeta } from "/opt/build/repo/pages/join.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as reset_45password4iBG212Ge4Meta } from "/opt/build/repo/pages/reset-password.vue?macro=true";
import { default as set_45passwordKLRgnMCIcZMeta } from "/opt/build/repo/pages/set-password.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/[...slug].vue")
  },
  {
    name: "approve-email",
    path: "/approve/:email()",
    component: () => import("/opt/build/repo/pages/approve/[email].vue")
  },
  {
    name: join9vB1FLnYxNMeta?.name,
    path: "/join",
    component: () => import("/opt/build/repo/pages/join.vue"),
    children: [
  {
    name: "join-additional",
    path: "additional",
    component: () => import("/opt/build/repo/pages/join/additional.vue")
  },
  {
    name: "join-contact",
    path: "contact",
    component: () => import("/opt/build/repo/pages/join/contact.vue")
  },
  {
    name: "join-details",
    path: "details",
    component: () => import("/opt/build/repo/pages/join/details.vue")
  },
  {
    name: "join",
    path: "",
    component: () => import("/opt/build/repo/pages/join/index.vue")
  },
  {
    name: "join-membership",
    path: "membership",
    component: () => import("/opt/build/repo/pages/join/membership.vue")
  },
  {
    name: "join-terms",
    path: "terms",
    component: () => import("/opt/build/repo/pages/join/terms.vue")
  },
  {
    name: "join-thank-you",
    path: "thank-you",
    component: () => import("/opt/build/repo/pages/join/thank-you.vue")
  }
]
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/build/repo/pages/login.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password4iBG212Ge4Meta || {},
    component: () => import("/opt/build/repo/pages/reset-password.vue")
  },
  {
    name: "set-password",
    path: "/set-password",
    meta: set_45passwordKLRgnMCIcZMeta || {},
    component: () => import("/opt/build/repo/pages/set-password.vue")
  }
]