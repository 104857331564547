<script lang="ts" setup>
import type { Entry } from "contentful";
import { accessLevel } from "~/lib/downloads";
import type { TypeDownloadSkeleton } from "~/types/contentful";

defineProps<{
  item: Entry<TypeDownloadSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
  type?: string;
}>();

const user = useUser();

const { production } = useRuntimeConfig().public;
</script>

<template>
  <template v-if="type === 'icon'">
    <NuxtLink
      v-if="accessLevel(user, item) === 'full'"
      :to="item.fields.file?.fields.file?.url"
      target="_blank"
      class="inline-flex size-10 items-center justify-center rounded-md bg-blue"
    >
      <NuxtIcon
        class="text-white"
        name="ussif:download"
      />
    </NuxtLink>

    <div
      v-else-if="accessLevel(user, item) === 'locked' || accessLevel(user, item) === 'pay'"
      class="inline-flex size-10 items-center justify-center rounded-md bg-blue"
    >
      <NuxtLink
        to="/login"
      >
        <NuxtIcon
          class="text-white"
          name="ussif:lock"
        />
      </NuxtLink>
    </div>
  </template>

  <template v-if="type === 'secondary'">
    <UiButton
      v-if="accessLevel(user, item) === 'full'"
      button-type="secondary"
      button-icon="download"
    >
      <NuxtLink
        :to="item.fields.file?.fields.file?.url"
        target="_blank"
      >
        Download
      </NuxtLink>
    </UiButton>
    <UiButton
      v-if="accessLevel(user, item) === 'pay'"
      button-type="secondary"
      button-icon="lock"
    >
      <NuxtLink
        :to="production ? 'https://buy.stripe.com/8wM9Db4vM1lR58I9AA' : 'https://buy.stripe.com/test_4gw02beJJ8tQd1e288'"
        target="_blank"
      >
        Purchase Report
      </NuxtLink>
    </UiButton>
  </template>
</template>
