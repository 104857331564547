export default function useResponsive() {
  const isMobile = ref(false);
  const isTablet = ref(false);
  const isDesktop = ref(false);
  const isLargeDesktop = ref(false);

  onMounted(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
  });

  const updateWidth = () => {
    isMobile.value = window.innerWidth <= 640;
    isTablet.value = window.innerWidth > 640 && window.innerWidth <= 1024;
    isDesktop.value = window.innerWidth > 1024;
    isLargeDesktop.value = window.innerWidth >= 1280;
  };

  return {
    isMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
  };
}
