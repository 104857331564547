import type { TypeDownload } from "~/types/contentful";
import type { TypeUser } from "~/types/auth";

export const accessLevel = (
  user: TypeUser | null,
  download: TypeDownload<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">,
) => {
  if (!download.fields.access || download.fields.access.includes("Public")) {
    return "full";
  } else if (download.fields.access.includes("Member") && user) {
    return "full";
  } else if (!user && download.fields.productId) {
    return "pay";
  } else {
    return "locked";
  }
};
