<script lang="ts" setup>
const props = defineProps<{
  cardContainer?: boolean;
  noPadding?: boolean;
  noHorisontalPadding?: boolean;
  noBottomPadding?: boolean;
}>();

const sectionClass = computed(() => ({
  "py-10 md:py-12 lg:py-20": !props.noPadding && !props.cardContainer && !props.noBottomPadding, // default padding
  "py-0": props.noPadding, // no padding
  "pt-10 md:pt-12 lg:pt-20": props.noBottomPadding, // no padding
  "py-0 md:py-12 lg:py-20": props.cardContainer && !props.noPadding, // card padding
}));

const containerClass = computed(() => ({
  "mx-auto px-6": !props.cardContainer && !props.noHorisontalPadding,
  "md:px-6": props.cardContainer,
  "px-0": props.noHorisontalPadding,
}));
</script>

<template>
  <section :class="sectionClass">
    <div
      class="2xl:container"
      :class="containerClass"
    >
      <slot />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.media-text section.html-block,
.block-tabbed-list section.html-block,
.block-text section.html-block,
.block-wysiwyg section.html-block {
  @apply py-0 mb-8;
}

.media-text section.html-block .container,
.block-tabbed-list section.html-block .container,
.block-tabbed-list section.html-block > div,
.block-text section.html-block .container,
.block-wysiwyg section.html-block .container,
.block-anchor-scroll section.html-block > div {
  @apply px-0;
}
</style>
