<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import nodeRenderers from "~/lib/node-renderers.js";
import type { TypeBlockAnchorScroll } from "~/types/contentful";

defineProps<{
  fields: TypeBlockAnchorScroll<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const activeSection = ref("");
const showMobileTabs = ref(false);

const setupScrollSpy = () => {
  const sections = document.querySelectorAll(".scroll-element");

  // Select all sections
  const observerOptions = {
    root: null, // Observe within the viewport
    rootMargin: "-50% 0px",
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        activeSection.value = entry.target.id; // Update active section
      }
    });
  }, observerOptions);

  // Observe each section
  sections.forEach((section) => {
    observer.observe(section);
  });

  // Cleanup function
  return () => {
    observer.disconnect();
  };
};

onMounted(() => {
  const cleanup = setupScrollSpy();
  onUnmounted(() => {
    cleanup();
  });
});

const setElementId = (title: string) => title.replaceAll(" ", "-").toLowerCase();
</script>

<template>
  <UiContainer class="block-anchor-scroll">
    <UiSectionTitle :title="fields.title" />

    <section>
      <div class="grid grid-cols-12 gap-x-4">
        <!-- desktop menu -->
        <nav class="col-span-4 max-lg:hidden">
          <div class="sticky top-10">
            <div
              v-for="(item, index) in fields.content"
              :key="index"
              class="whitespace-normal border-[#e9e9eb] px-7 py-3 max-lg:border-b-4 lg:border-l-4"
              :class="{
                '!border-blue':
                  activeSection === (item?.fields?.title ? setElementId(item.fields.title) : ''),
              }"
            >
              <NuxtLink
                :to="{
                  hash: item?.fields?.title ? `#${setElementId(item.fields.title)}` : '',
                }"
                class="transition-colors duration-200 hover:text-blue"
                :class="{
                  'font-bold text-blue':
                    activeSection === (item?.fields?.title ? setElementId(item.fields.title) : ''),
                }"
              >
                {{ item?.fields?.title }}
              </NuxtLink>
            </div>
          </div>
        </nav>

        <!-- mobile menu -->
        <nav class="sticky top-0 col-span-12 -mx-6 bg-white lg:hidden">
          <div class="border-t border-charcoal/5 px-6 py-4 shadow-md">
            <div
              class="flex w-full cursor-pointer items-center font-bold text-blue"
              @click="() => (showMobileTabs = !showMobileTabs)"
            >
              Menu
              <NuxtIcon
                class="ml-2 inline-block"
                :class="{
                  'rotate-180': showMobileTabs,
                }"
                name="ussif:arrow"
              />
            </div>
            <Collapse :when="showMobileTabs">
              <div class="pt-4">
                <div
                  v-for="(item, index) in fields.content"
                  :key="index"
                  class="flex"
                >
                  <NuxtLink
                    :to="{
                      hash: item?.fields?.title ? `#${setElementId(item.fields.title)}` : '',
                    }"
                    class="py-1"
                    :class="{
                      underline:
                        activeSection
                        === (item?.fields?.title ? setElementId(item.fields.title) : ''),
                    }"
                  >
                    {{ item?.fields?.title }}
                  </NuxtLink>
                </div>
              </div>
            </Collapse>
          </div>
        </nav>

        <!-- content -->
        <div class="col-span-12 lg:col-span-8">
          <section
            v-for="(item, index) in fields.content"
            :id="item?.fields?.title ? setElementId(item.fields.title) : ''"
            :key="index"
            class="scroll-element py-8"
          >
            <h3 class="">
              {{ item?.fields?.title }}
            </h3>
            <RichTextRenderer
              :document="item?.fields.content"
              :node-renderers="nodeRenderers"
            />
          </section>
        </div>
      </div>
    </section>
  </UiContainer>
</template>
