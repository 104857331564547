const redirects: { [0]: RegExp; [1]: string }[] = [
  [/(.+)\/+$/, "$1"], // Remove trailing slashes
  [/^\/index.asp/, "/"],
  [/^\/calendar_list.asp/, "/events-and-networking/events-calendar"],
  [/^\/trends/, "/research/trends-reports"],
];

const tempRedirects: { [0]: RegExp; [1]: string }[] = [
];

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) {
    return;
  }

  for (const redirect of tempRedirects) {
    if (to.path.match(redirect[0])) {
      return navigateTo(
        to.path.replace(redirect[0], redirect[1]).replace(/\/+/, "/"),
        {
          redirectCode: 307,
        },
      );
    }
  }

  for (const redirect of redirects) {
    if (to.path.match(redirect[0])) {
      return navigateTo(
        to.path.replace(redirect[0], redirect[1]).replace(/\/+/, "/"),
        {
          redirectCode: 301,
        },
      );
    }
  }
});
