<script setup lang="ts">
import { ModalsContainer } from "vue-final-modal";

const metaTitleBase = useContentConfig().value?.metaTitleBase ?? "";

useSeoMeta({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${metaTitleBase}` : metaTitleBase;
  },
});
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
    <ModalsContainer />
  </NuxtLayout>
</template>
